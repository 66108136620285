<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <div>
                                <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Add Block</DialogTitle>
                                <div class="mt-2 mb-2">
                                    <p class="text-sm text-gray-500">Add a new block to the sequence</p>
                                </div>

                                <div class="grid grid-cols-1 border-b gap-x-8 gap-y-10 border-gray-900/10 md:grid-cols-4">
                                    <div class="hidden px-4 py-2 bg-white sm:px-6 sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Actions</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 px-4 py-2 sm:grid-cols-4 md:col-span-3 sm:px-6">
                                        <label @click="addBlock('speak')" class="palette-label">
                                            <i class="pr-2 fas fa-microphone"></i>Speak
                                        </label>

                                        <label @click="addBlock('play')" class="palette-label">
                                            <i class="pr-2 fas fa-play"></i>Play
                                        </label>

                                        <label @click="addBlock('pause')" class="palette-label">
                                            <i class="pr-2 fas fa-pause"></i>Pause
                                        </label>

                                        <label @click="addBlock('hangup')" class="palette-label">
                                            <i class="pr-2 fas fa-phone-slash"></i>Hangup
                                        </label>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 border-b gap-x-8 gap-y-10 border-gray-900/10 md:grid-cols-4">
                                    <div class="hidden px-4 py-2 bg-white sm:px-6 sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Outbound</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 px-4 py-2 sm:grid-cols-4 md:col-span-3 sm:px-6">
                                        <label @click="addBlock('dial')" class="palette-label">
                                            <i class="pr-2 fas fa-phone-volume"></i>Dial
                                        </label>

                                        <label @click="addBlock('rep_lookup')" v-if="settings.lookup.features.rep_lookup_federal || settings.lookup.features.rep_lookup_state" class="palette-label">
                                            <i class="pr-2 fas fa-user-tie"></i>Rep Lookup
                                        </label>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 border-b gap-x-8 gap-y-10 border-gray-900/10 md:grid-cols-4">
                                    <div class="hidden px-4 py-2 bg-white sm:px-6 sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Prompts</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 px-4 py-2 sm:grid-cols-4 md:col-span-3 sm:px-6">

                                        <label @click="addBlock('collect_attribute')" class="palette-label">
                                            <i class="pr-2 fas fa-user-tag"></i>Collect Attr
                                        </label>

                                        <label @click="addBlock('record')" v-if="settings.talk.features.record" class="palette-label">
                                            <i class="pr-2 fas fa-microphone"></i>Record
                                        </label>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 border-b gap-x-8 gap-y-10 border-gray-900/10 md:grid-cols-4">
                                    <div class="hidden px-4 py-2 bg-white sm:px-6 sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Attributes</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 px-4 py-2 sm:grid-cols-4 md:col-span-3 sm:px-6">

                                        <label @click="addBlock('tag_attribute')" class="palette-label">
                                            <i class="pr-2 fas fa-user-tag"></i>Tag Attr
                                        </label>

                                        <label @click="addBlock('add_to_segment')" class="palette-label">
                                            <i class="pr-2 fas fa-user-plus"></i>Segment
                                        </label>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 border-b gap-x-8 gap-y-10 border-gray-900/10 md:grid-cols-4">
                                    <div class="hidden px-4 py-2 bg-white sm:px-6 sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Options</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 px-4 py-2 sm:grid-cols-4 md:col-span-3 sm:px-6">
                                        <label @click="addBlock('menu')" class="palette-label">
                                            <i class="pr-2 fas fa-list-ol"></i>Menu
                                        </label>

                                        <label @click="addBlock('random')" class="palette-label">
                                            <i class="pr-2 fas fa-random"></i>Random
                                        </label>

                                        <label @click="addBlock('poll')" class="palette-label">
                                            <i class="pr-2 fas fa-poll"></i>Poll
                                        </label>

                                        <label @click="addBlock('absplit')" class="palette-label">
                                            <i class="pr-2 fas fa-code-branch"></i>A/B Split
                                        </label>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 border-b gap-x-8 gap-y-10 border-gray-900/10 md:grid-cols-4">
                                    <div class="hidden px-4 py-2 bg-white sm:px-6 sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Logic</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 px-4 py-2 sm:grid-cols-4 md:col-span-3 sm:px-6">
                                        <label @click="addBlock('conditional')" class="palette-label">
                                            <i class="pr-2 fas fa-bezier-curve"></i>Conditional
                                        </label>

                                        <label @click="addBlock('forward_to_sequence')" class="palette-label">
                                            <i class="pr-2 fas fa-share"></i>Forward
                                        </label>

                                        <label @click="addBlock('date_gate')" class="palette-label">
                                            <i class="pr-2 fas fa-share"></i>Date Gate
                                        </label>

                                        <label @click="addBlock('date_conditional')" class="palette-label">
                                            <i class="pr-2 fas fa-share"></i>Date Condition
                                        </label>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 border-b gap-x-8 gap-y-10 border-gray-900/10 md:grid-cols-4">
                                    <div class="hidden px-4 py-2 bg-white sm:px-6 sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Advanced</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 px-4 py-2 sm:grid-cols-4 md:col-span-3 sm:px-6">
                                        <label @click="addBlock('dynamic_content')" class="palette-label">
                                            <i class="pr-2 fas fa-code"></i>Dynamic Res
                                        </label>

                                        <label @click="addBlock('notification')" class="palette-label">
                                            <i class="pr-2 far fa-paper-plane"></i>Notification
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import FormButton from '../../shared/form/Button'
    import FormSection from '../../shared/form/Section'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'
    import FormSelect from '../../shared/form/Select'
    import FormInput from '../../shared/form/Input'
    import FormValidation from '../../shared/form/ValidationNotice';
    import DynamicTextarea from '../../shared/DynamicTextarea'
    import MicRecorder from 'mic-recorder-to-mp3'

    export default {
        name: 'block-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormButton,
            FormSection,
            FormField,
            FormLabel,
            FormSelect,
            FormInput,
            FormValidation,
            DynamicTextarea,
            MicRecorder,
        },
        emits: [
            'add',
            'cancel',
            'update:currentID',
        ],
        props: {
            name: String,
            currentID: Number,
            errors: Object,
            settings: Object,
            actionText: {
                type: String,
                default: 'Add',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            current_id: {
                get() {
                    return this.currentID;
                },
                set(v) {
                    this.$emit('update:currentID', v)
                }
            },
        },
        methods: {
            addBlock(type) {
                let block = null;
                let current_id = this.current_id;

                if (type === 'speak') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'speak',
                        'params': {
                            'message': '',
                            'voice': 'alice',
                            'language': 'en-US',
                        },
                        'errors': [],
                    }
                } else if (type === 'dial') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'dial',
                        'params': {
                            'dial_list_id': null,
                            'source': 'numbers',
                            'numbers': [
                                {
                                    'number': '',
                                    'send_digits': '',
                                },
                            ],
                            'type': 'first',
                        },
                        'errors': [],
                    }
                } else if (type === 'play') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'play',
                        'play_settings': {
                            'default': {
                                'audio_type': 'file',
                                'recorder': new MicRecorder({ bitRate: 128 }),
                                'status': 'waiting',
                                'audio_url': null,
                            },
                        },
                        'params': {
                            'attachment_key': null,
                            'attachment_mimetype': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'hangup') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'hangup',
                        'params': {

                        },
                        'errors': [],
                    }
                } else if (type === 'pause') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'pause',
                        'params': {
                            'duration': 1,
                        },
                        'errors': [],
                    }
                } else if (type === 'menu') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'menu',
                        'choices': 1,
                        'play_settings': {
                            'request': {
                                'audio_type': 'file',
                                'recorder': new MicRecorder({ bitRate: 128 }),
                                'status': 'waiting',
                                'audio_url': null,
                            },
                            'invalid': {
                                'audio_type': 'file',
                                'recorder': new MicRecorder({ bitRate: 128 }),
                                'status': 'waiting',
                                'audio_url': null,
                            },
                        },
                        'params': {
                            'attribute_id': null,
                            'finish_on_key': '#',
                            'input_type': 'dtmf',
                            'profanity_filter': 'true',
                            'language': 'en-US',
                            'num_digits': '',
                            'timeout': 5,
                            'request': {
                                'type': 'speak',
                                'message': '',
                                'voice': 'alice',
                                'language': 'en-US',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                            'invalid': {
                                'type': 'speak',
                                'message': '',
                                'voice': 'alice',
                                'language': 'en-US',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'menu_item',
                                'params': {
                                    'key': '0',
                                    'name': 'Choice 1',
                                    'attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            }
                        ],
                        'errors': [],
                    }
                } else if (type === 'random') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'random',
                        'choices': 1,
                        'params': {
                            'attribute_id': null,
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'random_item',
                                'params': {
                                    'percent': 100,
                                    'name': 'Choice 1',
                                    'attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            }
                        ],
                        'errors': [],
                    }
                } else if (type === 'poll') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'poll',
                        'choices': 1,
                        'play_settings': {
                            'request': {
                                'audio_type': 'file',
                                'recorder': new MicRecorder({ bitRate: 128 }),
                                'status': 'waiting',
                                'audio_url': null,
                            },
                            'invalid': {
                                'audio_type': 'file',
                                'recorder': new MicRecorder({ bitRate: 128 }),
                                'status': 'waiting',
                                'audio_url': null,
                            },
                        },
                        'params': {
                            'attribute_id': null,
                            'finish_on_key': '#',
                            'input_type': 'dtmf',
                            'profanity_filter': 'true',
                            'language': 'en-US',
                            'num_digits': '',
                            'timeout': 5,
                            'voting_type': 'first_only',
                            'allow_external_access': 'false',
                            'send_results': 'false',
                            'request': {
                                'type': 'speak',
                                'message': '',
                                'voice': 'alice',
                                'language': 'en-US',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                            'invalid': {
                                'type': 'speak',
                                'message': '',
                                'voice': 'alice',
                                'language': 'en-US',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                            'voted': {
                                'type': 'speak',
                                'message': '',
                                'voice': 'alice',
                                'language': 'en-US',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                            'results': {
                                'type': 'speak',
                                'message': '',
                                'voice': 'alice',
                                'language': 'en-US',
                            },
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'poll_item',
                                'params': {
                                    'key': '0',
                                    'name': 'Choice 1',
                                    'attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            }
                        ],
                        'errors': [],
                    }
                } else if (type === 'absplit') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'absplit',
                        'choices': 1,
                        'params': {
                            'attribute_id': null,
                            'split_type': 'first_only',
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'absplit_item',
                                'params': {
                                    'percent': 100,
                                    'name': 'Choice 1',
                                    'attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            }
                        ],
                        'errors': [],
                    }
                } else if (type === 'add_to_segment') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'add_to_segment',
                        'params': {
                            'segment_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'collect_attribute') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'collect_attribute',
                        'play_settings': {
                            'request': {
                                'audio_type': 'file',
                                'recorder': new MicRecorder({ bitRate: 128 }),
                                'status': 'waiting',
                                'audio_url': null,
                            },
                            'valid': {
                                'audio_type': 'file',
                                'recorder': new MicRecorder({ bitRate: 128 }),
                                'status': 'waiting',
                                'audio_url': null,
                            },
                            'invalid': {
                                'audio_type': 'file',
                                'recorder': new MicRecorder({ bitRate: 128 }),
                                'status': 'waiting',
                                'audio_url': null,
                            },
                        },
                        'params': {
                            'request': {
                                'type': 'speak',
                                'message': '',
                                'voice': 'alice',
                                'language': 'en-US',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                            'valid': {
                                'type': 'speak',
                                'message': '',
                                'voice': 'alice',
                                'language': 'en-US',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                            'invalid': {
                                'type': 'speak',
                                'message': '',
                                'voice': 'alice',
                                'language': 'en-US',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                            'attribute_id': null,
                            'finish_on_key': '#',
                            'input_type': 'dtmf',
                            'profanity_filter': 'true',
                            'language': 'en-US',
                            'num_digits': '',
                            'timeout': 5,

                        },
                        'errors': [],
                    }
                } else if (type === 'conditional') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'conditional',
                        'params': {
                            'conditions': {
                                'operator': 'AND',
                                'conditions': []
                            },
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'true',
                                'blocks': [],
                                'errors': [],
                            },
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'false',
                                'blocks': [],
                                'errors': [],
                            },
                        ],
                        'errors': [],
                    }
                } else if (type === 'date_gate') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'date_gate',
                        'params': {
                            'date_start': null,
                            'date_end': null,
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'date_before',
                                'blocks': [],
                                'errors': [],
                            },
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'date_during',
                                'blocks': [],
                                'errors': [],
                            },
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'date_after',
                                'blocks': [],
                                'errors': [],
                            },
                        ],
                        'errors': [],
                    }
                } else if (type === 'date_conditional') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'date_conditional',
                        'params': {
                            'conditions': {
                                'operator': 'AND',
                                'conditions': []
                            },
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'date_condition',
                                'blocks': [],
                                'errors': [],
                            },
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'date_condition_default',
                                'blocks': [],
                                'errors': [],
                            },
                        ],
                        'errors': [],
                    }
                } else if (type === 'record') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'record',
                        'params': {
                            'timeout': 5,
                            'finish_on_key': '*',
                            'max_length': 10,
                            'play_beep': 'true',
                            'trim': 'do-not-trim',
                            'transcribe': 'false',
                        },
                        'errors': [],
                    }
                } else if (type === 'forward_to_sequence') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'forward_to_sequence',
                        'params': {
                            'sequence_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'tag_attribute') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'tag_attribute',
                        'params': {
                            'value': '',
                            'attribute_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'notification') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'notification',
                        'params': {
                            'type': 'email',
                            'recipient': '',
                            'text': '',
                            'subject': '',
                            'url': '',
                        },
                        'errors': [],
                    }
                } else if (type === 'dynamic_content') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'dynamic_content',
                        'params': {
                            'url': ''
                        },
                        'errors': [],
                    }
                } else if (type === 'rep_lookup') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'rep_lookup',
                        'params': {
                            'match_by': 'menu',
                            'filter_by': 'zip',
                            'value_type': 'match_attribute',
                            'attribute_id': null,
                            'value': '',
                            'level': 'federal',
                            'type': 'lower',
                            'party': null,
                            'include_reps': [],
                            'exclude_reps': [],
                            'fallback_numbers': [],
                            'fallback_dial_type': 'first_answer',
                        },
                        'errors': [],
                    }
                }

                this.current_id = current_id;
                this.$emit('add', block);
            },
        }
    };
</script>
